<template>
  <div class="row">
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header eu-modal-header">
                  <h5 class="modal-title eu-modal-title" style="color: #fff">
                    {{ notifyMeData.name }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="font-weight: 100">
                    <span aria-hidden="true" @click="showModal = false">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group" style="margin-bottom: 1rem">
                    <input type="text" class="form-control" v-model="name" placeholder="Your Name" />
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" v-model="mobileNo" placeholder="Your Mobile Number" />
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="showModal = false">
                    Close
                  </button>
                  <button type="button" class="btn btn-primary"
                    @click="notifyMeForSubmit(name, mobileNo, notifyMeData)">
                    Notify Me
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="col-md-12">
      <div class="thumbnail-img col-md-2">
        <ul v-if="productDetails.product_images.length > 0">
          <li class="thumb-img" v-for="image in productDetails.product_images">
            <img v-if="image.hasOwnProperty('large_image')" @click="asignImage(image.large_image)"
              :src="'https://eurasiasupplies.com/euadmin/' + image.large_image" alt="" />
          </li>
        </ul>
      </div>
      <div class="col-md-4 wrap-border">
        <div v-if="productDetails.product_images.length > 0" class="immage-zoomer">
          <inner-image-zoom class="large-img-sz" :src="'https://eurasiasupplies.com/euadmin/' +
            `${inImage == null ? productDetails.product_images[0].large_image : inImage
            }`
            " :zoomScale="1" zoomType="hover" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <div class="product-details">
              <div class="product-desc">
                <h1 style="
                    font-size: 1.4em;
                    font-weight: 700;
                    color: #252525;
                    text-align: start;
                    margin-bottom: 5px;
                  ">
                  {{ productDetails.name }}
                </h1>
                <p>
                  by
                  <b class="brand-name">
                    <router-link target="_blank" :to="{ name: 'brand', params: { slug: productDetails.brand.slug } }">
                      {{ productDetails.brand.name }}
                    </router-link>
                  </b>
                </p>
                <p class="sku">
                  <b>SKU : {{ productDetails.sku }}</b>
                </p>
              </div>
              <div class="price">
                <b>Sale:
                  <span class="price-tag">
                    <span v-if="
                      productDetails.product_price[0].offer_price > 0
                        ? productDetails.product_price[0].price > 0
                          ? productDetails.product_price[0].price
                          : ''
                        : '' !== ''
                    ">
                      Was:
                      <del>{{
                        productDetails.product_price[0].offer_price > 0
                          ? productDetails.product_price[0].price > 0
                            ? productDetails.product_price[0].price
                            : ""
                          : ""
                      }}</del>৳</span>
                    <span>
                      Now:
                      {{
                        productDetails.product_price[0].offer_price
                          ? productDetails.product_price[0].offer_price
                          : productDetails.product_price[0].price
                      }}৳
                      <span v-if="
                        productDetails.product_price[0].offer_price > 0
                          ? productDetails.product_price[0].price > 0
                            ? productDetails.product_price[0].price
                            : ''
                          : '' !== ''
                      ">(
                        {{
                          percentCalc(
                            productDetails.product_price[0].offer_price > 0
                              ? productDetails.product_price[0].price > 0
                                ? productDetails.product_price[0].price
                                : ""
                              : "",
                            productDetails.product_price[0].offer_price
                              ? productDetails.product_price[0].offer_price
                              : productDetails.product_price[0].price
                          )
                        }}% )</span>
                      <small style="color: grey; font-weight: 100; letter-spacing: 1px">/piece</small></span>
                  </span>
                </b>
                <p class="dsc">{{ productDetails.name }}</p>
                <p class="dsc">
                  Made in {{ productDetails.country_of_manufacture_id.name }}
                </p>
              </div>

              <div class="sales-btn-area">
                <div v-if="$store.getters.isExist(productDetails.id)" class="counter-btn">
                  <button class="btn" @click="deductQty(productDetails)">-</button>

                  <button class="value-btn" readonly>
                    {{ $store.getters.prodQty(productDetails.id) }}
                  </button>

                  <button class="btn" :disabled="productDetails.product_price[0].quantity ===
                    $store.getters.prodQty(productDetails.id)
                    " @click="addQty(productDetails)">
                    +
                  </button>
                </div>
              </div>
              <div class="stock" v-if="
                                        productDetails.product_type == 1">
                <p class="stock-st">
                  <b><span>Stock Status: </span>
                    <span v-if="productDetails.product_price[0].quantity > 0">{{
                      productDetails.product_price[0].quantity
                      }}</span>
                    <span v-else>0</span> available only</b>
                </p>
              </div>
            </div>
          </div>
          <div v-if="
            !$store.getters.isExist(productDetails.id) &&
            productDetails.product_price[0].quantity > 0
          " class="col-md-6">
            <div class="cart-btn">
              <button class="btn btn-primary buy-now" @click="buyNow(productDetails)">
                Buy Now
              </button>
            </div>
          </div>
          <div v-if="
            !$store.getters.isExist(productDetails.id) &&
            productDetails.product_price[0].quantity > 0
          " class="col-md-6">
            <div class="cart-btn">
              <button @click="addtoBag(productDetails)" class="btn btn-primary add-bag">
                Add to bag
              </button>
            </div>
          </div>
          <div v-if="
            !$store.getters.isExist(productDetails.id) &&
            productDetails.product_price[0].quantity <= 0
          " class="col-md-6 product_action">
            <button class="plus product-add-btn notify" @click="notifyMe(productDetails)">
              Sold Out. Notify Me
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="description-tab-area">
        <b-tabs content-class="mt-3">
          <b-tab title="Description" active>
            <div class="v-description" v-html="productDetails.description"></div>
          </b-tab>
          <b-tab title="Return Policy">
            <p></p>
          </b-tab>
          <b-tab title="Reviews">
            <p></p>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <RelatedProduct v-if="productDetails.releted_products.length > 0"
      :products="productDetails.releted_products[0].products" />
    <br />
    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import "../../assets/product_common_global.css";
import RelatedProduct from "../Home/RelatedProduct";

export default {
  name: "SingleProductDetails",
  props: [],
  data() {
    return {
      inImage: null,
      des: true,
      returnp: false,
      rev: false,
      showModal: false,
      notifyMeData: "",
      mobileNo: "",
      name: "",
      quickViewModal: false,
      proPrice: 0,
      singleProductImg: null,
      showPriceRange: true,
      productName: "",
      brandName: "",
      origin: "",
      slug: "",
      description: "",
      price: "",
      sku: "",
      deliveryCharge: "",
      colors: [],
      sizes: [],
      singleProct: {},
      selectedClrId: 0,
      isSelected: false,
      img: "",
      allProduct: [],
      apiLoad: false,
      productLoading: false,
      groupProductMsg: "",
    };
  },
  metaInfo() {
    return {
      title: this.productDetails.meta_title,
      titleTemplate: "%s | Eurasia",
      meta: [
        {
          name: "description",
          content: this.productDetails.meta_description,
        },
        {
          name: "keyword",
          content: this.productDetails.meta_keyword,
        },
      ],
    };
  },
  components: {
    "inner-image-zoom": InnerImageZoom,
    RelatedProduct,
  },
  computed: {
    ...mapGetters(["productDetails", "shoppingBag", "accessToken"]),
  },
  methods: {
    asignImage(img) {
      this.inImage = img;
    },
    addtoBag() {
      this.productDetails.qty = 1;
      this.productDetails.cartImage = this.productDetails.product_images[0].normal_image;
      this.productDetails.product_price[0].offer_price > 0
        ? (this.productDetails.unitPrice = this.productDetails.product_price[0].offer_price)
        : (this.productDetails.unitPrice = this.productDetails.product_price[0].price);
      this.$store.dispatch("addToBag", this.productDetails);
      // // console.log(this.productDetails);
    },
    buyNow() {
      this.productDetails.qty = 1;
      this.productDetails.cartImage = this.productDetails.product_images[0].normal_image;
      this.productDetails.product_price[0].offer_price > 0
        ? (this.productDetails.unitPrice = this.productDetails.product_price[0].offer_price)
        : (this.productDetails.unitPrice = this.productDetails.product_price[0].price);
      this.$store.dispatch("addToBag", this.productDetails);
      // console.log(this.productDetails);
      if (this.accessToken) {
        this.$router.push("/checkout").catch(() => { });
      } else {
        this.$router.push({ name: "login" }).catch(() => { });
      }
    },
    addQty(product) {
      // // console.log('update', product.product_price[0].tier_quantity_three)
      this.$store.dispatch("addQty", product);
      // console.log("add", product);
      // if (product.qty <= product.product_price[0].quantity) {
      //     if (
      //         product.qty >= product.product_price[0].tier_quantity_three &&
      //         product.product_price[0].tier_quantity_three !== 0
      //     ) {
      //         this.proPrice = product.product_price[0].tier_price_three;
      //     } else if (
      //         product.qty >= product.product_price[0].tier_quantity_two &&
      //         product.qty < product.product_price[0].tier_quantity_three
      //     ) {
      //         this.proPrice = product.product_price[0].tier_price_two;
      //     } else if (
      //         product.qty >= product.product_price[0].tier_quantity_one &&
      //         product.qty < product.product_price[0].tier_price_two
      //     ) {
      //         this.proPrice = product.product_price[0].tier_price_one;
      //     } else if (product.product_price[0].offer_price) {
      //         this.proPrice = product.product_price[0].offer_price;
      //     } else {
      //         this.proPrice = product.product_price[0].price;
      //     }
      // }
    },
    deductQty(product) {
      // console.log("deduct", product);
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) == 0) {
        this.$store.dispatch("removeFromCart", product);
      }
      // if (product.qty <= product.product_price[0].quantity) {
      //     if (
      //         product.qty >= product.product_price[0].tier_quantity_three &&
      //         product.product_price[0].tier_quantity_three !== 0
      //     ) {
      //         this.proPrice = product.product_price[0].tier_price_three;
      //     } else if (
      //         product.qty >= product.product_price[0].tier_quantity_two &&
      //         product.qty < product.product_price[0].tier_quantity_three
      //     ) {
      //         this.proPrice = product.product_price[0].tier_price_two;
      //     } else if (
      //         product.qty >= product.product_price[0].tier_quantity_one &&
      //         product.qty < product.product_price[0].tier_price_two
      //     ) {
      //         this.proPrice = product.product_price[0].tier_price_one;
      //     } else if (product.product_price[0].offer_price) {
      //         this.proPrice = product.product_price[0].offer_price;
      //     } else {
      //         this.proPrice = product.product_price[0].price;
      //     }
      // }
    },
    notifyMe(data) {
      this.notifyMeData = data;
      this.showModal = true;
    },
    notifyMeForSubmit(name, phone, product) {
      if (name !== "" && phone !== "" && product.id) {
        let notifyDataForPass = {
          name: name,
          phone: phone,
          product_id: product.id,
        };
        // console.log("notify", notifyDataForPass);
        this.$store.dispatch("soldOutNotifyMe", notifyDataForPass).then((res) => {
          // console.log("Response Data = ", res);
          if (res === undefined) {
            window.alert("Successfully Submitted.");
            this.mobileNo = "";
            this.name = "";
          }
        });
        // console.log("Data for pass: ", notifyDataForPass);
      }
      this.showModal = false;
    },
    percentCalc(wasVal, newVal) {
      if (newVal && wasVal) {
        var per = (newVal * 100) / wasVal;
        return Math.round(100 - per);
      }
    },
  },
};
</script>
<style>
.description-tab-area {
  text-align: left;
}

.description-tab-area ul li a {
  color: #000;
  font-weight: 600;
  font-size: 15px;
  padding: 15px 30px;
}

.description-tab-area ul li a.nav-link.active {
  color: #fff;
  background: #0a0a0a;
}

.v-description {
  font-size: 14px;
  padding: 15px;
}

.v-description p {
  font-size: 15px;
}
</style>
